import { Fragment, MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FilterReport, { DEFAULT_FILTER_VALUE, IFilter, FilterTypes } from "./FilterReport";
import { getAllHeadersAsTrue, getGridColumns, getIdLabelForEachView, getNumberOfFIlterApplied, getUserDetail, getUserName, transformFormValueToPresetValue, transformFormValueToValues, transformStringToTableState, transformTableStateToString, transformValueForSnowflakeAPI, transformValuesToFormValues, URL_OPTTION_SUBFIX } from "utils";
import AffiliationCard from "./AffiliationCard";
import CustomButton from "components/CustomButton";
import CustomButtonWithPopover from "components/CustomButton/CustomButtonWithPopover";

import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";

import CheckboxLabel from "components/CheckboxLabel";
import ReactTable, { CHANGE_EVENTS, IColumn, RefProps } from "components/ReactTable";
import { CellIDS, Cell_IDS_VIEW, getAffiliationExportItems, AFFILATIONS_VIEWS, getDefaultCardViews, SnoFlake_Cell_IDS_VIEW, SnowFlakeCellIDS, haveFilterChanges, hasPAPOnlyAccess, checkIfPapAdminRolesExists } from "./utils";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";

import "./SearchPaaf.scss";
import Loading from "components/Loading";
import { allAffiliationFetchPageState, getFeaturesState, getPresetFilterState, getSfAllAffiliationFetchMatrixVieweState, getSfAllAffiliationFetchPageState, getSfAllAffiliationFetchTableVieweState, getUserRoleState, gettoggleColumnState } from "store/selectors";
import URLRoutes from "urlRoutes";
import { Feature } from "utils/Features/Features";
import { ISearchPaafFeature } from "utils/Features/interface";
import { ReactTableDefaultState } from "components/ReactTable/ReactTable";
import { getSnoflakeAllAffiliations, getSnoflakeAllAffiliationsMatrixView, getSnoflakeAllAffiliationsTableView } from "store/pagination";
import Pagination from "../../components/Pagination";
import { getToggleColumns, getUserRolesRequest, updateToggleColumns } from "store/rext";
import { IColumSequence } from "interface";
import deepEqual from "deep-equal";
import { expandCollapseInteraction, iconInteraction, internalSearchInteraction, linkInteraction, setGlobalPageLoad, viewInteraction } from "Analytics";
import moment from "moment";
import { transformFormValueToAdobe } from "utils/filterFormTransform/transformValueToAdobe";
import { HEADRES } from "components/Header";
import { useOktaAuth } from "@okta/okta-react";



const AFFILITAION_VIEWS_ICONS: any = {
  [AFFILATIONS_VIEWS.GRID_VIEW]: CUSTOM_SVG_ICON.Grid,
  [AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW]: CUSTOM_SVG_ICON.Hamburger,
  [AFFILATIONS_VIEWS.ALL_AFFILIATIONS_VIEW]: CUSTOM_SVG_ICON.Hamburger,
  [AFFILATIONS_VIEWS.PA_MATRIX_VIEW]: CUSTOM_SVG_ICON.Hamburger
};

const AFFILITAION_VIEWS_LABEL: any = {
  [AFFILATIONS_VIEWS.GRID_VIEW]: "Card View",
  [AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW]: "Employee Grid - All Affiliations (Table)",
  [AFFILATIONS_VIEWS.ALL_AFFILIATIONS_VIEW]: "Employee Grid - All Affiliations",
  [AFFILATIONS_VIEWS.PA_MATRIX_VIEW]: "Practice Area Matrix Grid"
};


function SearchPaaf() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authState } = useOktaAuth();
  const { hrid: loggedInUserHrid } = getUserDetail(authState!);
  const { data: userRoleState } = useSelector(getUserRoleState);

  const [searchParams, setSearchParams] = useSearchParams();
  const { data: toggleData, fetching: toggleFetching, isUpdated } = useSelector(gettoggleColumnState, deepEqual);
  const [showNoSearchBanner] = useState<boolean>(true);

  // Filter State
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [currentFilterState, setCurrentFilterState] = useState<IFilter>(undefined!);

  // Table State
  const tableRef: MutableRefObject<RefProps> = useRef(null!);
  const [defaultTableState, setDefaultTableState] = useState<ReactTableDefaultState>(undefined!);
  const [currentTableState, setCurrentTableState] = useState<ReactTableDefaultState>(undefined!);

  // Search Affiliation State
  const [affiliationView, setAffiliationView] = useState<AFFILATIONS_VIEWS>(undefined!);
  const currentPageState = useSelector(allAffiliationFetchPageState, deepEqual);
  const [totalfilterApplied, setTotalFilterApplied] = useState<number>(0);
  const [expandAllAffiliation, setExpandAllAffiliations] = useState<boolean>(false);
  const { data: features }: { data: Feature; } = (useSelector(getFeaturesState, deepEqual))!;
  const searchPaafFeatures: ISearchPaafFeature = useMemo(() => features?.getSearchPaafFeatures(), [features]);
  const [dataState, setDataState] = useState<any>();
  const [tableView, setTableView] = useState("");
  const [filterChange, setFilterChange] = useState({});
  const [reportPayload, setReportPayload] = useState({});

  // Role state
  const [isPapRole, setIsPapRole] = useState<boolean>(false);
  const [isAdminPapRole, setIsAdminPapRole] = useState<boolean>(false);

  const snowFalkeCurrentPageState = useSelector(getSfAllAffiliationFetchPageState, deepEqual);
  const snowFalkeCurrentPageTableViewState = useSelector(getSfAllAffiliationFetchTableVieweState, deepEqual);
  const snowFalkeCurrentPageMatrixViewState = useSelector(getSfAllAffiliationFetchMatrixVieweState, deepEqual);


  const presetFilterState = useSelector(getPresetFilterState, deepEqual);


  const handleViewChange = (view: AFFILATIONS_VIEWS) => (event: any) => {
    setAffiliationView(view);
    viewInteraction(HEADRES.Search_PAAF, undefined!, view);
    setCurrentTableState({ ...currentTableState, pageNumber: 0 });
    tableRef?.current?.changePageNumber(0);
    setSearchParams({
      ...transformTableStateToString({ ...currentTableState, pageNumber: 0 }, false),
      ...transformFormValueToValues(currentFilterState, FilterTypes, false),
      "affiliationView": view
    })
  }

  const handleExpandCollapse = ({ target: { checked } }: any) => {
    expandCollapseInteraction(HEADRES.Search_PAAF, undefined!, checked ? "Expand" : "Collapse", undefined!);
    setExpandAllAffiliations(checked);
  }

  const handleExport = () => {
    linkInteraction("Export to Excel", undefined!, HEADRES.Search_PAAF, undefined!);
    const exportExcelItems = getAffiliationExportItems(toggleData.sequence || getDefaultCardViews(affiliationView), affiliationView);
    dispatch(modalOperation.showModal(ModalsType.ExportToExcel, {
      body: transformFormValueToPresetValue(currentFilterState, FilterTypes),
      url: `${URLRoutes.server.allAffiliations}`,
      urlParams: {
        gridType: affiliationView
      },
      excelCells: exportExcelItems,
      peopleApiKeys: ["hrId"],
      fileName: affiliationView,
      responseKey: "info",
      totalElements: dataState.totalElements,
      reportPayload,
      onSave: () => {
        dispatch(modalOperation.hideModal());
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      title: `Export to Excel`
    }))
  }

  const handleLeadAllocationExport = () => {
    linkInteraction("Export to Excel", undefined!, HEADRES.Search_PAAF, undefined!);
    const exportExcelItems = getAffiliationExportItems(getDefaultCardViews(AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW_LEAD_ALLOCATION), AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW_LEAD_ALLOCATION);
    dispatch(modalOperation.showModal(ModalsType.ExportToExcel, {
      body: transformFormValueToPresetValue(currentFilterState, FilterTypes),
      url: `${URLRoutes.server.allAffiliations}`,
      urlParams: {
        gridType: AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW_LEAD_ALLOCATION
      },
      excelCells: exportExcelItems,
      peopleApiKeys: ["hrId"],
      fileName: AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW_LEAD_ALLOCATION,
      responseKey: "info",
      totalElements: dataState.totalElements,
      reportPayload,
      onSave: () => {
        dispatch(modalOperation.hideModal());
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      title: `Export to Excel`
    }))
  }

  const handleLeadAllocationMatrixViewExport = () => {
    linkInteraction("Export to Excel", undefined!, HEADRES.Search_PAAF, undefined!);
    const exportExcelItems = getAffiliationExportItems(getDefaultCardViews(AFFILATIONS_VIEWS.PA_MATRIX_VIEW_LEAD_ALLOCATION), AFFILATIONS_VIEWS.PA_MATRIX_VIEW_LEAD_ALLOCATION);
    dispatch(modalOperation.showModal(ModalsType.ExportToExcel, {
      body: transformFormValueToPresetValue(currentFilterState, FilterTypes),
      url: `${URLRoutes.server.allAffiliations}`,
      urlParams: {
        gridType: AFFILATIONS_VIEWS.PA_MATRIX_VIEW_LEAD_ALLOCATION
      },
      excelCells: exportExcelItems,
      peopleApiKeys: ["hrId"],
      fileName: AFFILATIONS_VIEWS.PA_MATRIX_VIEW_LEAD_ALLOCATION,
      responseKey: "info",
      totalElements: dataState.totalElements,
      reportPayload,
      onSave: () => {
        dispatch(modalOperation.hideModal());
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      title: `Export to Excel`
    }))
  }

  const handleEdit = (values: any) => {
    const { hrId } = values;
    iconInteraction(HEADRES.Search_PAAF, undefined!, "Edit", getUserName(values));
    navigate(`${URLRoutes.client.EditAffiliations}/${hrId}`)
  }

  const handleChangeActivity = (values: any) => {
    const { hrId } = values;
    iconInteraction(HEADRES.Search_PAAF, undefined!, "Change", getUserName(values));
    navigate({
      pathname: URLRoutes.client.ChangeReport,
      search: `t_filter=hrId|${hrId}&activity=${moment().subtract(7, "days").toDate().getTime()}${URL_OPTTION_SUBFIX}${(new Date()).getTime()}`
    })
  }

  const fetchUsers = (currentAffiliationView: AFFILATIONS_VIEWS, tableState: ReactTableDefaultState, filter: IFilter) => {
    const { pageNumber, pageSize } = tableState;
    const searchParameters = transformFormValueToPresetValue(filter, FilterTypes);
    setTotalFilterApplied(getNumberOfFIlterApplied(filter, FilterTypes));
    setTableView(currentAffiliationView)

    const payload = transformValueForSnowflakeAPI(searchParameters);
    setReportPayload(payload)

    const isFilterChanged: boolean = haveFilterChanges(filter, filterChange);

    if ((currentAffiliationView === "ALL_AFFILIATIONS_VIEW" || currentAffiliationView === "GRID_VIEW") && (isFilterChanged || searchParameters.length === 0 || isFilterApplied)) {
      dispatch(getSnoflakeAllAffiliations(pageNumber, pageSize, payload, {
        gridType: currentAffiliationView
      }, true))
    } else if (currentAffiliationView === "ALL_AFFILIATIONS_TABLE_VIEW") {
      dispatch(getSnoflakeAllAffiliationsTableView(pageNumber, pageSize, payload, {
        gridType: currentAffiliationView
      }, true))
    } else if (currentAffiliationView === "PA_MATRIX_VIEW") {
      dispatch(getSnoflakeAllAffiliationsMatrixView(pageNumber, pageSize, payload, {
        gridType: currentAffiliationView
      }, true))
    }
    setFilterChange(filter)
  }

  useEffect(() => {
    if (affiliationView === AFFILATIONS_VIEWS.GRID_VIEW) {
      return;
    }
    if (affiliationView) {
      dispatch(getToggleColumns(affiliationView))
    }
  }, [affiliationView])

  useEffect(() => {
    if (tableView === "GRID_VIEW" || tableView === "ALL_AFFILIATIONS_VIEW") {
      setDataState(snowFalkeCurrentPageState)
    } else if (tableView === "ALL_AFFILIATIONS_TABLE_VIEW") {
      setDataState(snowFalkeCurrentPageTableViewState)
    } else if (tableView === "PA_MATRIX_VIEW") {
      setDataState(snowFalkeCurrentPageMatrixViewState)
    }
  }, [snowFalkeCurrentPageState, snowFalkeCurrentPageTableViewState, snowFalkeCurrentPageMatrixViewState, tableView])

  const gridOptions: IColumn[] = useMemo(() => {
    if (toggleFetching) {
      return [];
    }
    return getGridColumns(SnoFlake_Cell_IDS_VIEW, toggleData.sequence || getDefaultCardViews(affiliationView, features?.getSearchPaafFeatures()?.editUser), toggleData.showHide, { affiliationView })
  }, [toggleData, toggleFetching]);


  const renderLayoutButton = (key: string) => {
    if (key === 'LEAD_ALLOCATION_TABLE_VIEW' || key === 'LEAD_ALLOCATION_MATRIX_VIEW') return
    const isActive = affiliationView === key;
    return (
      <CustomButtonWithPopover
        key={key}
        primaryButton
        outline={!isActive}
        transparent={!isActive}
        iconPosition={ICON_POSITION.LEFT}
        iconProps={{
          svgType: SVGType.CUSTOM,
          name: AFFILITAION_VIEWS_ICONS[key],
          size: "small"
        }}
        baseclassname="margin-l-2"
        handleClick={handleViewChange(key as AFFILATIONS_VIEWS)}
        popupContent={AFFILITAION_VIEWS_LABEL[key]} />
    )
  }

  const renderAffiliationCardView = (affiliationData: any) => {
    return (
      <AffiliationCard key={affiliationData.hrId} data={affiliationData} hrId={affiliationData.hrId} expandAllAffiliation={expandAllAffiliation} handleEdit={searchPaafFeatures.editUser && handleEdit} handleChangeActivity={handleChangeActivity} />
    )
  }

  // changes done in table
  const handleChange = (changeEvent: CHANGE_EVENTS, tableState: ReactTableDefaultState) => {
    if (changeEvent !== CHANGE_EVENTS.PAGE_NUMBER) {
      tableState.pageNumber = 0;
    }
    setIsFilterApplied(true);
    setCurrentTableState({ ...tableState });
    setSearchParams({
      ...transformFormValueToValues(currentFilterState, FilterTypes, false),
      ...transformTableStateToString(tableState, false)
    })
  };

  const handlePageChange = (pageNumber: number) => {
    handleChange(CHANGE_EVENTS.PAGE_NUMBER, {
      ...currentTableState,
      pageNumber
    });
  }

  const handlePageSize = (pageSize: number) => {
    handleChange(CHANGE_EVENTS.PAGE_SIZE, {
      ...currentTableState,
      pageSize
    });
  }

  // changes done in filter
  const handleFilterApplied = (filterValue: IFilter) => {
    setAffiliationView(AFFILATIONS_VIEWS.GRID_VIEW);
    setIsFilterApplied(true);
    internalSearchInteraction(transformFormValueToAdobe(filterValue, FilterTypes), HEADRES.Search_PAAF, undefined!);
    setCurrentFilterState(filterValue);
    setCurrentTableState({ ...currentTableState, pageNumber: 0 });
    tableRef?.current?.changePageNumber(0);
    setSearchParams({
      ...transformTableStateToString({ ...currentTableState, pageNumber: 0 }, false),
      ...transformFormValueToValues(filterValue, FilterTypes, false)
    })
  }

  const handleToggleColumns = () => {
    linkInteraction("Toggle Columns", undefined!, HEADRES.Search_PAAF, undefined!);
    const defaultSeq = getDefaultCardViews(affiliationView, features?.getSearchPaafFeatures()?.editUser);
    dispatch(modalOperation.showModal(ModalsType.ToggleColumnModal, {
      onSave: (cards: IColumSequence) => {
        linkInteraction("Toggle Columns", "Apply & Save", HEADRES.Search_PAAF, undefined!);
        dispatch(modalOperation.hideModal());
        dispatch(updateToggleColumns(affiliationView, cards))
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      headers: getIdLabelForEachView(SnoFlake_Cell_IDS_VIEW, toggleData.sequence, defaultSeq),
      title: `${AFFILITAION_VIEWS_LABEL[affiliationView]} - Column Selector`,
      disableHeaders: [SnowFlakeCellIDS.First_Name, SnowFlakeCellIDS.Last_Name, SnowFlakeCellIDS.HR_ID, SnowFlakeCellIDS.Action],
      selectedHeaders: toggleData.showHide || getAllHeadersAsTrue(defaultSeq)
    }));
  }

  const handleResetClick = () => {
    linkInteraction("Reset", undefined!, HEADRES.Search_PAAF, undefined!);
    setIsFilterApplied(false);
    setCurrentFilterState(DEFAULT_FILTER_VALUE);
    setSearchParams({});
    setAffiliationView(AFFILATIONS_VIEWS.GRID_VIEW);
  }

  // Changed search to searchParams
  useEffect(() => {

    const searchParamsString = searchParams.toString();
    let tableState = transformStringToTableState(searchParamsString);
    let filter: any = transformValuesToFormValues(searchParamsString, FilterTypes);
    let currentAffiliationView: AFFILATIONS_VIEWS = searchParams.get("affiliationView") as AFFILATIONS_VIEWS || AFFILATIONS_VIEWS.GRID_VIEW;
    if (currentFilterState === undefined) {
      setCurrentFilterState(filter || DEFAULT_FILTER_VALUE);
    }

    if (currentTableState === undefined) {
      setCurrentTableState(tableState);
      setDefaultTableState(tableState);
    }

    if (affiliationView === undefined) {
      setAffiliationView(currentAffiliationView);
    }

    if (!showNoSearchBanner || (showNoSearchBanner && isFilterApplied)) {
      fetchUsers(currentAffiliationView, tableState, filter);
    }
  }, [searchParams])

  useEffect(() => {
    setGlobalPageLoad("Search PAAF");
  }, [])

  useEffect(() => {
    if (loggedInUserHrid) {
      dispatch(getUserRolesRequest(loggedInUserHrid || ''));
    }
  }, [loggedInUserHrid]);

  useEffect(() => {
    if (userRoleState !== undefined && Object.keys(userRoleState).length) {
      const papAccessOnly = hasPAPOnlyAccess(userRoleState);
      const papAdminAccess = checkIfPapAdminRolesExists(userRoleState);
      setIsPapRole(papAccessOnly);
      setIsAdminPapRole(papAdminAccess);
    }
  }, [userRoleState])

  return (
    <Fragment>

      {
        currentFilterState && <FilterReport
          filterState={currentFilterState}
          isFilterApplied={isFilterApplied}
          handleFilterApplied={handleFilterApplied}
          showNoFilterBanner={showNoSearchBanner}
          submitButtonText={"Search"}
          handleResetClick={handleResetClick}
          noFilterMessage="Use the above filters to find PAAF affiliated BCGers"
        />
      }



      {
        affiliationView && isFilterApplied &&
        <div className="position-relative padding-l-10 padding-r-10 background-grey-shade-7">

          <div className="flex flex-row flex-justify-between  padding-t-6">
            <div className="margin-r-2 padding-t-1 text-inline">{dataState?.totalElements} Search Results: {totalfilterApplied} Filters applied</div>

            <div className="grid-list-container">
              <span className="text-bold">Reports - </span>
              {
                Object.values(AFFILATIONS_VIEWS).map(renderLayoutButton)
              }
            </div>
          </div>

          <div className="flex flex-row flex-align-center margin-b-4 flex-justify-between">
            {
              affiliationView === AFFILATIONS_VIEWS.GRID_VIEW && <div className="margin-r-4"><CheckboxLabel
                labelTxt="Expand/Collapse"
                handleChange={handleExpandCollapse}
                baseClassName="left-align"
              /></div>
            }

            {
              affiliationView !== AFFILATIONS_VIEWS.GRID_VIEW &&
              <div className="margin-t-4">
                {searchPaafFeatures?.exportToExcel && <CustomButton
                  round
                  outline
                  primaryButton
                  disabled={dataState?.fetching}
                  buttonText="Export To Excel"
                  handleClick={handleExport}
                  baseclassname="margin-r-4" />
                }
                <CustomButton
                  round
                  outline
                  primaryButton
                  disabled={dataState?.fetching}
                  buttonText="Toggle Columns"
                  handleClick={handleToggleColumns} />
              </div>
            }
            {(affiliationView == AFFILATIONS_VIEWS.ALL_AFFILIATIONS_TABLE_VIEW && (isAdminPapRole || isPapRole)) && (
              <CustomButton
                round
                primaryButton
                disabled={dataState?.fetching || dataState?.totalElements === 0}
                buttonText="Table Lead Allocation Report"
                handleClick={handleLeadAllocationExport} />
            )}

            {(affiliationView == AFFILATIONS_VIEWS.PA_MATRIX_VIEW && (isAdminPapRole || isPapRole)) && (
              <CustomButton
                round
                primaryButton
                disabled={dataState?.fetching || dataState?.totalElements === 0}
                buttonText="Matrix Lead Allocation Report"
                handleClick={handleLeadAllocationMatrixViewExport} />
            )}
          </div>

          {
            affiliationView === AFFILATIONS_VIEWS.GRID_VIEW &&
            <div className="flex flex-wrap flex-column padding-t-4">
              {dataState?.fetching && <Loading isGlobal />}
              {dataState?.data.map(renderAffiliationCardView)}
              {!!dataState?.totalElements && <Pagination pageSize={currentTableState.pageSize} totalNoOfElements={dataState?.totalElements} pageNumber={currentTableState.pageNumber} totalNoOfPage={dataState.totalNumberOfPages}
                handlePageChange={handlePageChange} handlePageSizeChange={handlePageSize} isLoading={dataState?.fetching!} />
              }
              {
                !dataState?.totalElements && <div className="grid-no-message width-100 flex flex-align-center flex-justify-center">Please try with some other criteria.</div>
              }
            </div>
          }

          {
            affiliationView !== AFFILATIONS_VIEWS.GRID_VIEW &&
            <ReactTable
              type="snow-flake-Data"
              headers={gridOptions}
              data={dataState?.data}
              ref={tableRef}
              primarykey="genericId"
              defaultState={defaultTableState}
              isLoading={dataState?.fetching}
              isPaginationAllowed
              handleChange={handleChange}
              totalElements={dataState?.totalElements}
              totalNumberOfPages={dataState?.totalNumberOfPages}
              noDataMessage="Please try with some other criteria."
              uncontrolledHeight={affiliationView === AFFILATIONS_VIEWS.ALL_AFFILIATIONS_VIEW}
              baseClassName="paaf-user-table-container"
            />
          }
        </div>
      }
      {(presetFilterState.operationProgress || isUpdated) && <Loading isGlobal />}
    </Fragment>
  )
}
export default SearchPaaf;
