import { ActionIdentity, IPaginationAction, IPaginationMeta, IPaginationActionParams } from './interface';
import { defineRequestType} from "../common";

export function createIdentityAction(identity: string): ActionIdentity {
  return {
    PAGINATION_FETCH: defineRequestType(`@${identity}CUSTOM_REDUX_PAGINATION_FETCH`),
    CHANGE_PAGE: defineRequestType(`@${identity}PAGINATION_CHANGE_PAGE`),
    ADD_PAGE_ITEM: defineRequestType(`@${identity}PAGINATION_ADD_ITEM_PAGE`),
    UPDATE_PAGE_ITEM: defineRequestType(`@${identity}PAGINATION_UPDATE_ITEM_PAGE`),
    DELETE_PAGE_ITEM: defineRequestType(`@${identity}PAGINATION_DELETE_ITEM_PAGE`),
  };
}

export const paginationActionFunctions = {
  request: (meta: IPaginationMeta, params: IPaginationActionParams, showNotifyMessage?: string): IPaginationAction => {
    const { actions } = meta;
    return {
      type: actions.REQUEST,
      meta,
      payload: {
        params,
        showNotifyMessage
      },
    };
  },
  success: (meta: IPaginationMeta, params: IPaginationActionParams, items: any, totalNumberOfPages: number, totalElements: number, message: string): IPaginationAction => {
    const { actions } = meta;
    return {
      type: actions.SUCCESS,
      meta,
      payload: {
        params,
        items,
        message,
        totalNumberOfPages,
        totalElements
      },
    };
  },
  failure: (meta: IPaginationMeta, params: IPaginationActionParams, message: string): IPaginationAction => {
    const { actions } = meta;
    return {
      type: actions.FAILURE,
      meta,
      payload: {
        params,
        message,
      },
    };
  },
};