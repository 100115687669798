import classNames from "classnames";
import CheckboxLabel from "components/CheckboxLabel";
import CustomButton from "components/CustomButton";
import { IRextState } from "baseProvider/rext";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { FormTextArea } from "components/FormInputs";
import { MultiSelectDropdown } from "components/MultiSelectDropdown";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { memo, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliationState } from "store/selectors";
import { debounce } from "utils";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { ITopic } from "interface";
import { useParams } from "react-router-dom";
import { Accordion, AccordionContent, AccordionTitle, Icon } from "semantic-ui-react";

interface Props {
  isParent?: boolean;
  title: string;
  id: string;
  defaultTopic?: ITopic;
  handleChange: (id: string, value: ITopic) => void;
  deleteTopic: (id: string) => void;
  expand: (expand: any) => any;
  previousUpdationStatus: boolean;
  currentUpdationStatus: boolean;
  parentPracticeArea?: string;
  isAdminPAP?: boolean;
  isPAPOnly?: boolean;
  isExpaned?: any;
}

function getParentPracticeArea(parentPracticeArea: string, title: string) {
  return parentPracticeArea || title
}

function getTopic(parentPracticeArea: string, title: string) {
  return parentPracticeArea ? title :  "";
}

const percentageDropdown = {
  0: '0%',
  20: '20%',
  40: '40%',
  50: '50%',
  60: '60%',
  80: '80%',
  100: '100%'
};

const EditTopic = memo(({ isParent, title, id, defaultTopic, handleChange, deleteTopic, expand, previousUpdationStatus, currentUpdationStatus, parentPracticeArea, isAdminPAP, isPAPOnly, isExpaned }: Props) => {
  const affiliationLevelState: IRextState = useSelector(getAffiliationState);
  const dispatch = useDispatch();
  const { hrid } = useParams();
  const [comment, setComment] = useState<string>("");
  const [affiliationLevelId, setAffiliationLevelId] = useState<string>(undefined!);
  const [leadAllocation, setLeadAllocation] = useState<string>(undefined!);
  const [selectedFlag, setSelectedFlag] = useState<boolean>(undefined!);
  const [commentsMandatory, setCommentsMandatory] = useState<boolean>(false);
  const [affiliationMandatory, setAffiliationMandatory] = useState<boolean>(false);
  const commentRef: MutableRefObject<HTMLInputElement> = useRef(null!);
  const dropdownRef: MutableRefObject<any> = useRef(null!);
  const allocationDropdownRef: MutableRefObject<any> = useRef(null!);
  const [expanded, setExpanded] = useState<boolean>(false);

  const showPreviousComments = () => {
    dispatch(modalOperation.showModal(ModalsType.PreviousCommentsModal, {
      hrid: hrid,
      topicId: id,
      onSave: () => {
        dispatch(modalOperation.hideModal());
      },
      onClose: () => {
        dispatch(modalOperation.hideModal());
      },
      title: "Previous Comments",
    }));
  }

  useEffect(() => {
    resetTopic();
  }, [defaultTopic]);

  useEffect(() => {
    resetAllocation();
  }, [isAdminPAP, isPAPOnly]);

  useEffect(() => {
    if (previousUpdationStatus && !currentUpdationStatus) {
      resetTopic();
    }
  }, [previousUpdationStatus, currentUpdationStatus])


  const handleAllChanges = (key: string, value: any) => {

    const latestTopicChanges = {
      comment,
      selectedFlag,
      affiliationLevelId,
      leadAllocation,
      [key]: value
    };
    if(latestTopicChanges.affiliationLevelId !== '3' && leadAllocation?.length) {
      latestTopicChanges.leadAllocation = '';
      allocationDropdownRef?.current?.forceResetToOptions('');
    }
    handleChange(
      id,
      latestTopicChanges
    )
  };
  
  const handleAffiliationChanges = (data: string) => {
    setAffiliationLevelId(data);
    if(!isPAPOnly || !isAdminPAP) {
      setCommentsMandatory(true);
    }
    if(data !== '2' && leadAllocation?.length) {
      handleAllChanges("leadAllocation", '');
    }
    handleAllChanges("affiliationLevelId", data);
  };

  const handleLeadAllocationChanges = (data: string) => {
    setLeadAllocation(data);
    if(isPAPOnly || isAdminPAP) {
      setAffiliationMandatory(true);
    }
    handleAllChanges("leadAllocation", data);
  };

  const handleSChanges = ({ target: { checked } }: any) => {
    setSelectedFlag(checked);
    setCommentsMandatory(true);
    handleAllChanges("selectedFlag", checked);
  };

  const handleCommentChanges = useCallback(debounce(({ target: { value } }: any) => {
    setComment(value);
    handleAllChanges("comment", value)
  }, 500), [affiliationLevelId, selectedFlag, leadAllocation]);

  const resetComments = (shouldDelete?: boolean) => {
    setCommentsMandatory(false);
    // commentRef.current.value = "";
    setComment("");

    if(shouldDelete) {
      deleteTopic(id);
    }
  }

  const resetTopic = () => {
    resetComments();
    const afiliationValue = defaultTopic?.affiliationLevelId || null;
    dropdownRef.current.forceResetToOptions(afiliationValue)
    setAffiliationLevelId(afiliationValue!);
    setSelectedFlag(defaultTopic?.selectedFlag || false);
  }

  const resetAllocation = () => {
    const allocationValue = defaultTopic?.leadAllocation || null;
    allocationDropdownRef?.current?.forceResetToOptions(allocationValue);
    setLeadAllocation(allocationValue!);
  }

  const {practiceAreaTitle, topicTitle} = useMemo(() => {
    return {
      practiceAreaTitle: getParentPracticeArea(parentPracticeArea!, title),
      topicTitle: getTopic(parentPracticeArea!, title)
    }
  }, [parentPracticeArea, title])

  const onClickExpand = (id: string, practiceAreaTitle: string) => {
    setExpanded(prev => !prev)
    const data = {
      id,
      expanded: !expanded,
      practiceAreaTitle,
      isGlobalChecked: false
    }
    expand(data);
  }

  return (
    <>
    <tr className={classNames([{ "dark": isParent, "row-collapsed": (!isExpaned?.expanded && !isParent) }])}>
      <td>{isParent && <div className="flex flex-align-center cursor-pointer expand-collapse-icon-wrapper" onClick={() => onClickExpand(id, practiceAreaTitle)}>
        {((isExpaned.expanded && (isExpaned.practiceAreaTitle === practiceAreaTitle)) || (isExpaned?.isGlobalChecked && isExpaned.practiceAreaTitle === '')) ? <Icon name='minus' size='small' /> : <Icon name='plus' size='small' />} {practiceAreaTitle}</div>}
        {!isParent && practiceAreaTitle}
        </td>
      <td>{topicTitle}</td>
      <td>
        <MultiSelectDropdown
          ref={dropdownRef}
          onChange={handleAffiliationChanges}
          options={affiliationLevelState.data}
          placeholder={""}
          isClearable={false}
          isLoading={affiliationLevelState.fetching}
          defaultValues={defaultTopic?.affiliationLevelId}
          closeMenuOnSelect={true}
          hideSelectedOptions={true}
          isSearchable={false}
          outlineSquare
          baseClassName={`edit-topic-dropdown ${(!isAdminPAP || isPAPOnly) ? 'is-disabled' : ''}`}
          controlShouldRenderValue
          disabled={!isAdminPAP || isPAPOnly}
        />
        {affiliationMandatory && <div className="text-danger-color text-sm padding-l-4">{(affiliationMandatory && affiliationLevelId?.length === 0) ? "Required" : undefined!}</div>}
      </td>
      <td>
        <CheckboxLabel
          baseClassName={`edit-topic-checkbox ${(!isAdminPAP || isPAPOnly) ? 'is-disabled' : ''}`}
          labelTxt=""
          checked={selectedFlag || false}
          handleChange={handleSChanges}
          disabled={!isAdminPAP || isPAPOnly}
        />
      </td>
      <td>
        <FormTextArea
          meta={{
            error: (commentsMandatory && comment?.trim().length === 0) ? "Required" : undefined!,
            touched: commentsMandatory
          }}
          input={{
            ref: commentRef,
            onChange: handleCommentChanges
          }}
          disabled={isPAPOnly}
        />
      </td>
      <td>
        <CustomButton
          primaryButton
          transparent
          noOutline
          noPadding
          buttonText="Previous Comments"
          iconPosition={ICON_POSITION.RIGHT}
          iconProps={{
            name: CUSTOM_SVG_ICON.NewTab,
            svgType: SVGType.CUSTOM,
            size: "small",
            baseclassname: "margin-l-2"
          }}
          handleClick={showPreviousComments}
          disabled={isPAPOnly}
        />
      </td>
      {(isAdminPAP || isPAPOnly) && (
        <td>
        {isParent && (
          <MultiSelectDropdown
            ref={allocationDropdownRef}
            onChange={handleLeadAllocationChanges}
            options={percentageDropdown}
            placeholder={""}
            isClearable={false}
            isLoading={false}
            defaultValues={defaultTopic?.leadAllocation}
            closeMenuOnSelect={true}
            hideSelectedOptions={true}
            isSearchable={false}
            outlineSquare
            baseClassName="edit-topic-dropdown"
            controlShouldRenderValue
            disabled={(defaultTopic?.affiliationLevel !== 'Lead' && affiliationLevelId !== '3') || (affiliationLevelId !== '3')}
          />
        )}
        </td>
      )}
    </tr>
    </>
  );
})

export default EditTopic;